<template>
  <main>
    <Row :gutter="15">
      <Col :span="10">
        <ButtonGroup shape="circle">
          <Button icon="md-refresh" @click="loadData">刷新</Button>
          <Button icon="md-add-circle" @click="create" v-if="Auth([ 'QuestionBank', 'create' ])">添加</Button>
          <Button
            icon="md-download"
            @click="exportFile"
            v-if="Auth([ 'QuestionBank', 'export' ])"
          >导出</Button>
        </ButtonGroup>
      </Col>
       <Col :span="7" class="text-right">
        <Cascader :data="cascaderOptions" placeholder="选择分类/科目" @on-change="onChangeCascader" v-width="300"/>
      </Col>
      <Col :span="7">
        <searcher ref="searcher" :options="searches" @on-change="loadData"></searcher>
      </Col>
    </Row>
    <Divider/>
    <Tabs type="card" @on-click="changeType">
      <TabPane label="全部" name="0"></TabPane>
      <TabPane label="单选题" name="1"></TabPane>
      <TabPane label="多选题" name="2"></TabPane>
      <TabPane label="填空题" name="3"></TabPane>
      <TabPane label="判断题" name="4"></TabPane>
      <TabPane label="论述题" name="5"></TabPane>
      <TabPane label="阅读理解" name="6"></TabPane>
    </Tabs>
    <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>

    <Divider/>
    <pagination ref="pagination" :total="total" @on-change="loadData"/>
  </main>
</template>

<script>
export default {
  data() {
    return {
      searches: {
        question_name: "题目"
      },
      question_type: 0,
      question_category_id: 0,
      question_subject_id: 0,
      question_section_id: 0,
      cascaderOptions: [],
      sort: "",
      total: 0,
      data: [],
      columns: [
        {
          title: "ID",
          key: "id",
          sortable: "custom",
          align: "center",
          minWidth: 80
        },
        {
          title: "题目分类",
          sortable: "custom",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            return h("span", params.row.section.subject.category.name);
          }
        },
        {
          title: "科目",
          sortable: "custom",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            return h("span", params.row.section.subject.name);
          }
        },
        {
          title: "章节",
          sortable: "custom",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            return h("span", params.row.section.name);
          }
        },
        {
          title: "题目",
          key: "question_name",
          sortable: "custom",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            if (params.row.question_name_type) {
              return h("div", { class: "text-center" }, [
                h("img", {
                  attrs: {
                    src: params.row.question_name_url
                      ? params.row.question_name_url
                      : ""
                  },
                  class: "contain block-center mg-tb-xs"
                })
              ]);
            } else {
              return h("span", params.row.question_name.substr(0, 10));
            }
          }
        },
        {
          title: "题目类型",
          key: "question_type",
          sortable: "custom",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            switch (params.row.question_type) {
              case 1:
                return h("Tag", { props: { color: "orange" } }, "单选题");

              case 2:
                return h("Tag", { props: { color: "green" } }, "多选题");

              case 3:
                return h("Tag", { props: { color: "cyan" } }, "填空题");

              case 4:
                return h("Tag", { props: { color: "gold" } }, "判断题");

              case 5:
                return h("Tag", { props: { color: "blue" } }, "论述题");

              case 6:
                return h("Tag", { props: { color: "purple" } }, "阅读理解");

              default:
                return h("Tag", "-");
            }
          }
        },
        {
          title: "选项A",
          key: "option1",
          sortable: "custom",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            if (params.row.option_type) {
              return h("div", { class: "text-center" }, [
                h("img", {
                  attrs: {
                    src: params.row.option1_url ? params.row.option1_url : ""
                  },
                  class: "contain block-center mg-tb-xs"
                })
              ]);
            } else {
              return h("span", params.row.option1.substr(0, 10));
            }
          }
        },
        {
          title: "选项B",
          key: "option2",
          sortable: "custom",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            if (params.row.option_type) {
              return h("div", { class: "text-center" }, [
                h("img", {
                  attrs: {
                    src: params.row.option2_url ? params.row.option2_url : ""
                  },
                  class: "contain block-center mg-tb-xs"
                })
              ]);
            } else {
              return h("span", params.row.option2.substr(0, 10));
            }
          }
        },
        {
          title: "选项C",
          key: "option3",
          sortable: "custom",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            if (params.row.option_type) {
              return h("div", { class: "text-center" }, [
                h("img", {
                  attrs: {
                    src: params.row.option3_url ? params.row.option3_url : ""
                  },
                  class: "contain block-center mg-tb-xs"
                })
              ]);
            } else {
              return h("span", params.row.option3.substr(0, 10));
            }
          }
        },
        {
          title: "选项D",
          key: "option4",
          sortable: "custom",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            if (params.row.option_type) {
              return h("div", { class: "text-center" }, [
                h("img", {
                  attrs: {
                    src: params.row.option4_url ? params.row.option4_url : ""
                  },
                  class: "contain block-center mg-tb-xs"
                })
              ]);
            } else {
              return h("span", params.row.option4.substr(0, 10));
            }
          }
        },
        {
          title: "正确答案",
          align: "center",
          minWidth: 180,
          render: (h, params) => {
            if (params.row.answer_type) {
              return h("div", { class: "text-center" }, [
                h("img", {
                  attrs: {
                    src: params.row.answer_url ? params.row.answer_url : ""
                  },
                  class: "contain block-center mg-tb-xs"
                })
              ]);
            } else {
              switch (params.row.question_type) {
                case 1:
                  return h("span", params.row.answer);

                case 2:
                  return h("span", params.row.answer);

                case 3:
                  return h("span", params.row.answer.substr(0, 10));

                case 4:
                  return h("span", params.row.answer ? "正确" : "错误");

                case 5:
                  return h("span", params.row.answer.substr(0, 10));

                default:
                  return h("Tag", "-");
              }
            }
          }
        },
        {
          title: "答题次数",
          key: "records_count",
          sortable: "custom",
          align: "center",
          minWidth: 130,
          render: (h, params) => {
            return h("span", params.row.records_count);
          }
        },
        {
          title: "创建时间",
          minWidth: 180,
          key: "created_at",
          align: "center",
          sortable: "custom"
        },
        {
          title: "状态",
          minWidth: 90,
          key: "status",
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h("Icon", {
              props: {
                type: params.row.status
                  ? "ios-checkmark-circle"
                  : "ios-remove-circle"
              },
              class: params.row.status ? "text-green" : "text-red"
            });
          }
        },
        {
          title: "操作",
          minWidth: 110,
          align: "center",
          render: (h, params) => {
            var html = [];

            if (this.Auth(["QuestionBank", "update"])) {
              html.push(
                h("Button", {
                  props: {
                    type: "success",
                    size: "small",
                    icon: "md-create"
                  },
                  class: "mg-lr-xs",
                  on: {
                    click: () => {
                      this.update(params.row);
                    }
                  }
                })
              );
            }

            // if (this.Auth(["QuestionBank", "delete"])) {
            //   html.push(
            //     h("Button", {
            //       props: {
            //         type: "error",
            //         size: "small",
            //         icon: "md-trash"
            //       },
            //       class: "mg-lr-xs",
            //       on: {
            //         click: () => {
            //           this.remove(params.row);
            //         }
            //       }
            //     })
            //   );
            // }

            return h("div", html);
          }
        }
      ]
    };
  },
  mounted() {
    this.loadData();
    this.loadCategorys();
  },
  methods: {
    loadData() {
      let params = {
        question_category_id:this.question_category_id,
        question_subject_id:this.question_subject_id,
        question_section_id:this.question_section_id,
        question_type: this.question_type,
        search_key: this.$refs.searcher.search_key,
        keywords: this.$refs.searcher.keywords,
        sort: this.sort,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit
      };

      this.$http.get("/questionBank/list", { params: params }).then(res => {
        this.total = res.total;

        for (let i = 0; i < res.data.length; i++) {
          if (
            res.data[i].question_type == 0 ||
            res.data[i].question_type == 1
          ) {
            let oldAnswer = res.data[i].answer;
            let temp1 = oldAnswer.replace("0", "A");
            let temp2 = temp1.replace("1", "B");
            let temp3 = temp2.replace("2", "C");
            let temp4 = temp3.replace("3", "D");
            res.data[i].answer = temp4;
          }
        }
        this.data = res.data;
      });
    },
    changeSort(item) {
      this.sort = item.order == "normal" ? "" : item.key + "," + item.order;
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    changeType(val) {
      this.question_type = val;
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    create() {
      this.$router.push("/question-bank/create");
    },
    update(item) {
      this.$router.push("/question-bank/update/" + item.id);
    },

    remove(item) {
      this.$Modal.confirm({
        title: "确定要删除该渠道吗？",
        content: item.name,
        onOk: () => {
          this.$http.post("/questionBank/delete/id/" + item.id).then(res => {
            this.loadData();
          });
        }
      });
    },
    loadCategorys() {
      this.$http.get("/questionCategory/list").then(res => {
        
        let data = [{value:0,label:'全部'}];
        res.data.forEach(cagegory => {
          let tempCagegory = {
            value: cagegory.id,
            label: cagegory.name,
            children: []
          };

          cagegory.subjects.forEach(subject => {
            tempCagegory.children.push({
              value: subject.id,
              label: subject.name
            });
          });
          data.push(tempCagegory);
        });
        this.cascaderOptions = data;
      });
    },
    onChangeCascader(e) {
      this.question_category_id = e[0];
      if (e.length > 1) {
        this.question_subject_id = e[1];
      }else{
        this.question_subject_id = 0;
      }
      if (e.length > 2) {
        this.question_section_id = e[2];
      }else{
        this.question_section_id = 0;
      }
      this.$refs.pagination.page = 1;
      this.loadData()
    },
    exportFile() {
      let params = {
        search_key: this.$refs.searcher.search_key,
        keywords: this.$refs.searcher.keywords,
        begin_date: this.begin_date,
        end_date: this.end_date,
        sort: this.sort,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit,
        token: window.localStorage.getItem("token")
      };

      let url = this.$http.defaults.baseURL + "/questionBank/export?";

      window.open(buildURL(url, params));
    }
  }
};
</script>